span.MuiBadge-root.status-badge {
    margin: 0 auto;
}

span.MuiBadge-root.status-badge span.MuiBadge-badge {
    position: static;
    display: flex;
    transform: translate(0px);
}

span.MuiBadge-root.status-badge.success span.MuiBadge-badge {
    background-color: lightgreen;
    color: green;
}

span.MuiBadge-root.status-badge.error span.MuiBadge-badge {
    background-color: #fdcec5;
    color: red;
}

:where(.css-dev-only-do-not-override-17sses9).ant-input-disabled,
:where(.css-dev-only-do-not-override-17sses9).ant-input[disabled] {
    color: rgb(0 0 0 / 76%);
}

.ant-modal-root .ant-modal-wrap {
    z-index: 1101 !important;
}

.copy-url:hover {
    text-decoration: underline;
}

.ellipsis-text {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}

.ant-card-body:has(div.rdt_Table) {
    padding: 0 !important;
}

.rdt_TableHeadRow {
    background: #36363b !important;
    color: white;
}

.EditTonnageModal input,
.EditTonnageModal textarea {
    color: black !important;
}

.EditTonnageModal .ant-modal-body {
    padding-block: 18px;
}

.EditTonnageModal .ant-col .ant-form-item {
    margin-bottom: 10px;
}

.EditTonnageModal .ant-col .ant-form-item .ant-col.ant-form-item-label {
    padding: 0;
    font-weight: 500;
}

.EditTonnageModal .ant-col .ant-form-item label {
    font-size: 12px;
}

.Resubcribe-Tabs .ant-tabs-tab {
    padding: 8px 16px;
    border: 1px solid darkslategrey;
}

.Resubcribe-Tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 100%;
    z-index: -1;
    background: #39393F;
}


.Resubcribe-Tabs .ant-tabs-tab .ant-tabs-tab-btn {
    position: relative;
    z-index: 7;
}

.Resubcribe-Tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
}

table.resubscribe-mail-table {
    /* max-width: 700px; */
    width: 100%;
    /* margin: 0 auto; */
}


.resubscribe-mail-container {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    background: white;
    padding: 20px 12px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px #bfbfbf;
}

table.resubscribe-mail-table th span {
    display: block;
    text-align: left;
    background: lightgrey;
    padding: 4px 10px;
}

.emailColumn {
    padding: 4px 10px;
    transition: 0.35s;
}

.emailColumn:hover {
    background: lightgrey;
    font-weight: 600;
}

span.ant-select-selection-item {
    color: black;
}

/* .rdt_TableHead .rdt_TableHeadRow {
    background: #6969696b;
    min-height: 35px;
} */


span.status_tags {
    display: block;
    padding: 6px 8px;
    white-space: nowrap;
    border-radius: 4px;
    background: slategrey;
    font-weight: 600;
    border: 1px solid;
    min-width: 100px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
}

span.status_tags.Approvedtags {
    background: #adff2fa8;
    color: green;
}

span.status_tags.Pendingtags {
    background: #ffff0057;
    color: chocolate;
}

span.status_tags.Processingtags {
    background: rgba(255, 127, 80, 0.63);
    color: brown;
}

span.status_tags.Activetags {
    background: #00ff00db;
    color: darkgreen;
}

span.importOptionsTag {
    background: #53989c;
    border: 1px solid;
    border-radius: 4px;
    margin: 2px;
    padding: 3px 6px 6px;
    transition: .35s ease;
    white-space: nowrap;
    min-width: 80px;
    min-height: auto;
    text-align: center;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    line-height: 1;
    color: white;
    border-color: #357174;
}

.importOptionRow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 8px 0px;
}

span.importOptionsTag:hover {
    background: black;
    color: white;
}

.invisibleEye {
    position: absolute;
    bottom: 3px;
    right: 10px;
    transform: translateY(-50%);
}
  
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');

.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0,0,0,.2));
  font-size: 60px;
  text-align: center;
}
.waviy span {
  font-family: 'Alfa Slab One', cursive;
  position: relative;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  animation: waviy 2.5s infinite;
  animation-delay: calc(.2s * var(--i));
  
}
@keyframes waviy {
  0%,40%,100% {
    transform: translateY(0)
  }
  20% {
    transform: translateY(-20px)
  }
}

.ant-card.ant-card-bordered.coming-soon-card {
    padding-block: 170px;
}