.autoComp {
    border: 1px solid #e0e0e0 !important;
    border-radius: 5px;
  }

  .autoComp fieldset {
    border: none !important;
    height:"1.8rem"!important
  }

  .search-input .MuiInputBase-root {
   
    padding:0px !important;
    /* background-color: white !important; */
  }

  .white-spin .ant-spin-dot {
    color: white !important;
  }

  